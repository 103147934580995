
<script>
export default {
    computed:{
        item(){
            return this.$store.getters["storage/item"];
        },
    },
    data() {
        return {
            folderName: "Folder New",
            invalidFolderName : "Your account or Email is incorrect.",
            validFolderName : "",
            stateFolderName : true,
            counter : 0
        };
    },
    methods:{
      cancel(){
        this.$store.commit("share/SET_SHOWFILE",false);
        this.$root.$emit('bv::hide::modal', 'modalDownload');
      },
      download(){
        if(this.item.isFolder){
            this.$store.dispatch("storage/downloadFolder",
            {
                id : this.item._id,
                file_name : this.item.file_name+".zip"
            }
            );
        }else{
            this.$store.dispatch("storage/download",
            {
                directory : this.item.directory,
                hash_name : this.item.hash_name,
                file_name : (this.item.file_name.split('.').pop() == this.item.extension) ? this.item.file_name : this.item.file_name+"."+this.item.extension
            }
            );
        }
        this.$root.$emit('bv::hide::modal', 'modalDownload');
      }
    }
};
</script>
<template>
    <b-modal id="modalDownload" no-fade  content-class="shadow" :hide-header="true"  :hide-footer="true"  centered title="Donwload File">
        <div v-if="item != null">
            <h2 class="modal-title text-dark bold-normal default-text-style">Download {{item.isFolder ? 'Folder' : 'File'}} 📬</h2>
            <p class="mt-3 default-text-style">Are You Want to Download "{{item.file_name}}" ?</p>
            <div class="d-flex justify-content-end align-items-center">
                <a href="javascript:void(0);" @click="cancel()" class="font-weight-medium default-text-style"><span class="text-blue">Cancel</span></a>
                <button class="btn base-btn btn-storage mx-4 " @click="download()">
                    <span class="default-text-style">Download</span>
                </button>
            </div>
        </div>
    </b-modal>
</template>